/* Style for user pop over */
.popover,
.popoverMenuList {
  color: var(--one-color-foreground-base-1);
  background-color: var(--one-color-background-base-1);
}
.UserInfoSection {
  @extend .popover;
  height: 124px;
  width: 230px;
  padding: 16px 16px 0px 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-bottom: 1px solid var(--one-color-legacy-gray-300);
}
.UserLogo {
  // @extend .typography-variant-subtitle1;
  border-radius: 100%;
  background-color: var(--one-color-petrol-500);
  font-size: var(--one-text-font-size-px-20);
  font-family: var(--one-text-font-family-default);
  font-weight: var(--one-text-font-weight-400);
  color: var(--one-color-foreground-neutral-2);
  letter-spacing: 0px;
  width: 40px;
  height: 40px;
  max-width: 40px;
  max-height: 40px;
  line-height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 0 12px 0;
}
.UserName {
  font-size: var(--one-text-font-size-px-16);
  font-weight: var(--one-text-font-weight-400);
  line-height: 16px;
  letter-spacing: 0px;
  margin-bottom: 4px;
  margin-bottom: 4px;
  text-transform: capitalize;
  max-width: 200px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.UserEmail {
  width: 200px;
  font-size: var(--one-text-font-size-px-14);
  font-weight: var(--one-text-font-weight-400);
  width: 200px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
